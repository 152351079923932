<template>
  <div class="d-flex justify-content-between align-items-center w-100">
    <span id="logo">uniConnect</span>
    <div>
      <button type="button" class="btn color-unidark"><i class="fal fa-cog"></i></button>
      <button type="button" class="btn color-unidark"><i class="fal fa-bell"></i></button>
      <span class="rounded-circle account-dropdown ms-1 p-2 text-light dp__pointer" data-bs-toggle="dropdown" aria-expanded="false">{{getInitials}}</span>
      <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
        <li><a class="dropdown-item" href="#" @click.prevent="logout">Uitloggen</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import store from '@/store/user'

export default {
  name: "navHeader",
  computed: {
    isProduction() {
        return process.env.VUE_APP_IS_PRODUCTION == "true";
    },
    getName () {
      return store.getters.getName
    },
    getInitials () {
      const name = store.getters.getName;
      const nameAsArray = name ? name.split(' ') : 'X';
      const initialOne = nameAsArray[0][0];
      const initialTwo = nameAsArray.at(-1)[0];
      return initialOne == initialTwo ? initialOne : (initialOne + '' + initialTwo);
    },
    getProfileImage () {
      return store.getters.getProfileImage
    }
  },
  methods: {
    logout() {
      store.dispatch('userLogout')
      location.reload();
    }
  }
};
</script>

<style scoped>
.fa-cog{
  rotate: 0deg;
  transition: rotate 0.25s
}
button:hover > .fa-cog{
  rotate: 60deg
}
button:hover > .fa-bell{
  animation: 0.5s bell ease-in-out
}
@keyframes bell{
  from{
    rotate: 0deg
  }
  30%{
    rotate: 15deg
  }
  70%{
    rotate: -15deg
  }
  to{
    rotate: 0deg;
  }
}
</style>