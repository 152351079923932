import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    bookkeeping_systems: [],
    currencies: [],
    reservation_systems: [],
    hospitality_groups: [],
    franchises: [],
    sources: [],
    show_reservations: false,
    show_locations: 0
  },
  plugins: [createPersistedState({
    "key": "data"
  })],
  mutations: {
    GET_DATA(state, userData) {
      state.bookkeeping_systems = userData.bookkeeping_systems
      state.currencies = userData.currencies
      state.reservation_systems = userData.reservation_systems
      state.hospitality_groups = userData.hospitality_groups
      state.franchises = userData.franchises
      state.sources = userData.sources
      state.show_reservations = userData.show_reservations
      state.show_locations = userData.show_locations
    },
  },
  actions: {
    getData({getters, commit}, response) {
      commit('GET_DATA', response)
    },
  },
  modules: {
  },
  getters: {
    getBookkeepingSystems: state => {
      return state.bookkeeping_systems
    },
    getCurrencies: state => {
      return state.currencies
    },
    getReservationSystems: state => {
      return state.reservation_systems
    },
    getHospitalityGroups: state => {
      return state.hospitality_groups
    },
    getFranchises: state => {
      return state.franchises
    },
    getSources: state => {
      return state.sources
    },
    getShowReservations: state => {
      return state.show_reservations
    },
    getLocationsToShow: state => {
      return state.show_locations
    }
  }
})
