import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login/Login.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/:origin',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/callback/:type',
    name: 'Callback',
    component: Login
  },
  {
    path: '/dagstaten',
    children: [
      {
        path: '',
        name: 'Dagstaten',
        component: () => import('../views/Daystate.vue')
      },
      {
        path: ':id',
        name: 'Dagstaat',
        component: () => import('../components/daystate/DaystateDetail.vue')
      }
    ]
  },
  {
    path: '/locaties',
    children: [
      {
        path: '',
        name: 'Locaties',
        component: () => import('../views/Customers.vue')
      },
      {
        path: ':id',
        name: 'Locatie',
        component: () => import('../components/customer/CustomerDetail.vue')
      }
    ]
  },
  {
    path: '/ketens',
    children: [
      {
        path: '',
        name: 'Ketens',
        component: () => import('../views/Franchises.vue')
      },
      {
        path: ':id',
        name: 'Keten',
        component: () => import('../components/franchise/FranchiseDetail.vue')
      }
    ]
  },
  {
    path: '/groepen',
    children: [
      {
        path: '',
        name: 'Groepen',
        component: () => import('../views/Holdings.vue')
      },
      {
        path: ':id',
        name: 'Groep',
        component: () => import('../components/holding/HoldingDetail.vue')
      }
    ]
  },
  {
    path: '/knowledge-base',
    children: [
      {
        path: '',
        name: 'Knowledge Base',
        component: () => import('../views/KnowledgeBase.vue')
      },
      {
        path: ':id',
        name: 'Knowledge Base Detail',
        component: () => import('../components/log/KnowledgeBaseDetail.vue')
      }
    ]
  },
  {
    path: '/dealers',
    children: [
      {
        path: '',
        name: 'Dealers',
        component: () => import('../views/Dealers.vue')
      },
      {
        path: ':id',
        name: 'Dealer',
        component: () => import('../components/dealer/DealerDetail.vue')
      }
    ]
  },
  {
    path: '/gebruikers',
    children: [
      {
        path: '',
        name: 'Gebruikers',
        component: () => import('../views/Users.vue')
      },
      {
        path: ':id',
        name: 'Gebruiker',
        component: () => import('../components/user/UserDetail.vue')
      }
    ]
  },
  {
    path: '/logs',
    children: [
      {
        path: '',
        name: 'Logs',
        component: () => import('../views/Logs.vue')
      },
      {
        path: ':id',
        name: 'Details Log',
        component: () => import('../components/log/LogDetail.vue')
      }
    ]
  },
  {
    path: '/reserveringen',
    children: [
      {
        path: '',
        name: 'Reserveringen',
        component: () => import('../views/Reservation.vue')
      },
      {
        path: ':id',
        name: 'Details reservering',
        component: () => import('../components/reservation/ReservationDetail.vue')
      }
    ]
  },
  {
    path: '/client-connections',
    name: 'Broker Client Connections',
    component: () => import('../views/ClientConnections.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
