import http from '@/http-common'

const URL = '/api'

class DataService {
  getData() {
    return http.get(`${URL}/get-store-data`)
  }
}

export default new DataService()