<template>
  <ul id='menulist' class="list-unstyled flex-column mb-auto" :data-expanded="expanded">
    <li id="uni-logo" class="d-flex">
      <div style="padding: 13px 8px 12px 12px">
        <img src="../assets/icon-menu.png" height="40"/>
      </div>
      <span id="logo" style="padding-left: 35px">uniConnect</span>
    </li>
    <template v-if="expanded">
      <li><router-link to="/" @click="unexpand(1)">
        <i class="fal fa-home"></i><span>{{$tc('navigation.home', 2)}}</span>
      </router-link></li>
      <li><router-link to="/dagstaten" @click="unexpand(1)">
        <i class="fal fa-coins"></i><span>{{$tc('navigation.daystates', 2)}}</span>
      </router-link></li>
      <li v-if="showReservations"><router-link to="/reserveringen" @click="unexpand(1)">
        <i class="fal fa-desktop"></i><span>{{$tc('navigation.reservations', 2)}}</span>
      </router-link></li>
      <li :class="{'group-open': !collapsed}" v-if="isNotCustomer">
        <router-link to="/locaties" @click="unexpand(2)">
          <i class="fal fa-user-group"></i><span>{{$tc('navigation.customers', 2)}}</span>
        </router-link>
      </li>
      <div class="collapser collapse" :class="{'show': !collapsed, 'collapsing': collapsing, 'collapse': !collapsing}" v-if="isNotCustomer">
        <li><router-link to="/locaties" @click="unexpand(0)">
          <i class="fal fa-location-dot"></i><span>{{$tc('navigation.locations', 2)}}</span>
        </router-link></li>
        <li><router-link to="/ketens" @click="unexpand(0)">
          <i class="fal fa-buildings"></i><span>{{$tc('navigation.franchises', 2)}}</span>
        </router-link></li>
        <li><router-link to="/groepen" @click="unexpand(0)">
          <i class="fal fa-sitemap"></i><span>{{$tc('navigation.holdings', 2)}}</span>
        </router-link></li>
      </div>
      <li v-else-if="showLocationsOverview"><router-link to="/locaties" @click="unexpand(1)">
        <i class="fal fa-location-dot"></i><span>{{$tc('navigation.locations', 2)}}</span>
      </router-link></li>
      <li v-else-if="showLocationsDirect"><router-link :to="'/locaties/'+getDirectLocation" @click="unexpand(1)">
        <i class="fal fa-location-dot"></i><span>{{$tc('navigation.locations', 1)}}</span>
      </router-link></li>
      <li v-if="isAdmin"><router-link to="/dealers" @click="unexpand(1)">
          <i class="fal fa-building"></i><span>{{$tc('navigation.dealers', 2)}}</span>
      </router-link></li>
      <template v-if="isNotCustomer">
        <li><router-link to="/gebruikers" @click="unexpand(1)">
          <i class="fal fa-user"></i><span>{{$tc('navigation.users', 2)}}</span>
        </router-link></li>
        <li><router-link to="/logs" @click="unexpand(1)">
          <i class="fal fa-clock-rotate-left"></i><span>{{$tc('navigation.logs', 2)}}</span>
        </router-link></li>
        <li><router-link to="/knowledge-base" @click="unexpand(1)">
          <i class="fal fa-brain"></i><span>{{$tc('navigation.knowledge_base', 2)}}</span>
        </router-link></li>
      </template>
    </template>
    <template v-else>
      <li><router-link to="/" data-bs-toggle="tooltip" data-bs-placement="right" title="Home" @click="collapse">
        <i class="fal fa-home"></i><span>{{$tc('navigation.home', 2)}}</span>
      </router-link></li>
      <li><router-link to="/dagstaten" data-bs-toggle="tooltip" data-bs-placement="right" title="Dagstaten" @click="collapse">
        <i class="fal fa-coins"></i><span>{{$tc('navigation.daystates', 2)}}</span>
      </router-link></li>
      <li v-if="showReservations"><router-link to="/reserveringen" data-bs-toggle="tooltip" data-bs-placement="right" title="Reserveringen" @click="collapse">
        <i class="fal fa-desktop"></i><span>{{$tc('navigation.reservations', 2)}}</span>
      </router-link></li>
      <li :class="{'group-open': !collapsed}" v-if="isNotCustomer">
        <router-link to="/locaties" data-bs-toggle="tooltip" data-bs-placement="right" title="Klanten" @click="uncollapse">
          <i class="fal fa-user-group"></i><span>{{$tc('navigation.customers', 2)}}</span>
        </router-link>
      </li>
      <div class="collapser" :class="{'show': !collapsed, 'collapsing': collapsing, 'collapse': !collapsing}" :style="{height: heightSet ? '195px' : ''}" v-if="isNotCustomer">
        <li><router-link to="/locaties" data-bs-toggle="tooltip" data-bs-placement="right" title="Locaties">
          <i class="fal fa-location-dot"></i><span>{{$tc('navigation.locations', 2)}}</span>
        </router-link></li>
        <li><router-link to="/ketens" data-bs-toggle="tooltip" data-bs-placement="right" title="Ketens">
          <i class="fal fa-buildings"></i><span>{{$tc('navigation.franchises', 2)}}</span>
        </router-link></li>
        <li><router-link to="/groepen" data-bs-toggle="tooltip" data-bs-placement="right" title="Groepen">
          <i class="fal fa-sitemap"></i><span>{{$tc('navigation.holdings', 2)}}</span>
        </router-link></li>
      </div>
      <li v-else-if="showLocationsOverview"><router-link to="/locaties" data-bs-toggle="tooltip" data-bs-placement="right" title="Locaties">
        <i class="fal fa-location-dot"></i><span>{{$tc('navigation.locations', 2)}}</span>
      </router-link></li>
      <li v-else-if="showLocationsDirect"><router-link :to="'/locaties/'+getDirectLocation" data-bs-toggle="tooltip" data-bs-placement="right" title="Locatie">
        <i class="fal fa-location-dot"></i><span>{{$tc('navigation.locations', 1)}}</span>
      </router-link></li>
      <li v-if="isAdmin"><router-link to="/dealers" data-bs-toggle="tooltip" data-bs-placement="right" title="Dealers" @click="collapse">
        <i class="fal fa-building"></i><span>{{$tc('navigation.dealers', 2)}}</span>
      </router-link></li>
      <template v-if="isNotCustomer">
        <li><router-link to="/gebruikers" data-bs-toggle="tooltip" data-bs-placement="right" title="Gebruikers" @click="collapse">
          <i class="fal fa-user"></i><span>{{$tc('navigation.users', 2)}}</span>
        </router-link></li>
        <li><router-link to="/logs" data-bs-toggle="tooltip" data-bs-placement="right" title="Logs" @click="collapse">
          <i class="fal fa-clock-rotate-left"></i><span>{{$tc('navigation.logs', 2)}}</span>
        </router-link></li>
        <li><router-link to="/knowledge-base" data-bs-toggle="tooltip" data-bs-placement="right" title="KnowledgeBase" @click="collapse">
          <i class="fal fa-brain"></i><span>{{$tc('navigation.knowledge_base', 2)}}</span>
        </router-link></li>
      </template>
    </template>
    <li class="stick-to-bottom" @click="expand">
      <a>
      <i class="fal" :class="{'fa-chevrons-right': !expanded, 'fa-chevrons-left': expanded}"></i>
      <span>&nbsp;</span>
    </a>
    </li>
  </ul>
</template>

<script>
import store from '@/store/user'
import storedata from '@/store/data'
import router from '@/router'

import { Tooltip } from 'bootstrap'

export default {
  name: "navMenu",
  data() {
        return {
          expanded: false,
          collapsed: true,
          collapsing: false,
          heightSet: false
        }
    },
  computed: {
    isProduction() {
        return process.env.VUE_APP_IS_PRODUCTION == "true";
    },
    isNotCustomer() {
        return [1, 3].includes(store.getters.getUserType);
    },
    isAdmin() {
        return [1].includes(store.getters.getUserType);
    },
    showReservations() {
        return this.isNotCustomer || storedata.getters.getShowReservations;
    },
    showLocationsDirect() {
        return !this.isNotCustomer && storedata.getters.getLocationsToShow;
    },
    showLocationsOverview() {
        return !this.isNotCustomer && storedata.getters.getLocationsToShow == 'all';
    },
    getDirectLocation() {
        return storedata.getters.getLocationsToShow;
    }
  },
  watch:{
    /*$route (to, from){
        if(from.name != undefined && from.name != "Callback") {
          return;
        }


        let active_items = document.querySelectorAll('#menulist .link-dark[href="' + to.href + '"]');
        if(active_items.length > 0) {
          let active_menu = active_items[0].parentNode.parentNode.parentNode;
          let active_menu_btn = active_menu.previousSibling;



          active_menu.classList.add("show");
          active_menu_btn.setAttribute("aria-expanded", "true");

          // active_menu_btn.click();
        }
    }*/
  },
  methods:{
    expand(){
      this.expanded = !this.expanded;
    },
    unexpand(collapseStatus = 1){
      this.expanded = false;
      if(collapseStatus == 1){
        this.collapse();
      }
      else if(collapseStatus == 2){
        this.uncollapse();
      }
    },
    collapse(){
      this.collapsed = true;
      this.collapsing = true;
      this.heightSet = false;
      window.setTimeout(() => {
        this.collapsing = false;
      }, 400);
    },
    uncollapse(){
      this.collapsing = true;
      window.setTimeout(() => this.heightSet = true, 1);
      window.setTimeout(() => {
        this.collapsed = false;
        this.collapsing = false;
      }, 400);
    },
    openLocations(){
      this.collapsed = false;
      this.heightSet = true;
    }
  },
  mounted(){
    new Tooltip(document.body, { selector: "[data-bs-toggle='tooltip']", trigger: "hover" });
  }
};
</script>